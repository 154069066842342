import styled from '@emotion/styled';

interface SubmitButtonType {
  type: 'submit';
  text: string;
}

const Button = styled.button`
  width: 100%;
  height: 36px;
  padding: 8px;
  border: none;
  border-radius: 8px;
  background: #3c79f5;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
`;

export default function SubmitButton({ type, text }: SubmitButtonType) {
  return (
    <Button aria-label="submit-button" type={type}>
      {text}
    </Button>
  );
}
