import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link, navigate } from 'gatsby';
import { isLoggedIn } from '../../../services/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import LoginInput from 'components/atoms/input/LoginInput';
import LoginTooltip from 'components/atoms/tooltip/LoginTooltip';
import SubmitButton from 'components/atoms/button/SubmitButton';

interface LoginFormType {
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
  handleInputUpdate: React.ChangeEventHandler<HTMLInputElement>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  padding: 50px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 8px 8px 16px #d1d9e6;
`;

const HomeIcon = styled.div`
  margin-bottom: 5px;
`;

const LoginLabel = styled.label`
  display: block;
  color: #181823;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 26px;
  margin-top: 30px;
`;

export default function LoginForm({
  handleSubmit,
  handleInputUpdate,
}: LoginFormType) {
  const [alert, setAlert] = useState(false);

  const checkAccount = () => {
    if (isLoggedIn()) {
      navigate(`/`);
    } else {
      setAlert(true);
    }
  };

  return (
    <Container>
      <HomeIcon>
        <Link to="/" style={{ color: '#181823' }}>
          <FontAwesomeIcon icon={faHouse} />
        </Link>
      </HomeIcon>
      <LoginLabel>Sign in</LoginLabel>
      <Form
        onSubmit={(e) => {
          handleSubmit(e);
          checkAccount();
        }}
      >
        <LoginInput
          label={`Username`}
          type={`text`}
          name={`username`}
          placeholder={`유저명을 입력하세요.`}
          handleInputUpdate={handleInputUpdate}
        />
        <LoginInput
          label={`Password`}
          type={`password`}
          name={`password`}
          placeholder={`패스워드를 입력하세요.`}
          handleInputUpdate={handleInputUpdate}
        />
        {alert && <LoginTooltip />}
        <SubmitButton type={`submit`} text={`Sign in`} />
      </Form>
    </Container>
  );
}
