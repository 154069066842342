interface InfoType {
  username: string;
  password: string;
}

const isBrowser = typeof window !== `undefined`;

const getUser = () =>
  window.localStorage.gatsbyUser
    ? JSON.parse(window.localStorage.gatsbyUser)
    : {};

const setUser = (user: object) =>
  (window.localStorage.gatsbyUser = JSON.stringify(user));

export const handleLogin = ({ username, password }: InfoType) => {
  if (!isBrowser) return false;

  if (username === `raey` && password === `physics`) {
    return setUser({
      name: `Master`,
      legalName: `Master User`,
      email: `nxnaxx125@gmail.com`,
    });
  }

  return false;
};

export const isLoggedIn = () => {
  if (!isBrowser) return false;

  const user = getUser();

  return !!user.email;
};

export const getCurrentUser = () => isBrowser && getUser();

export const logout = (callback: () => void) => {
  if (!isBrowser) return;
  setUser({});
  callback();
};
