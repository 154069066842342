import React from 'react';
import styled from '@emotion/styled';

interface LoginInputType {
  label: string;
  type: string;
  name: string;
  placeholder: string;
  handleInputUpdate: React.ChangeEventHandler<HTMLInputElement>;
}

const Label = styled.label`
  color: #181823;
  font-size: 14px;
`;

const Input = styled.input`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: 8px;
  border: 1px solid #eee;
  border-radius: 8px;
  background: rgba(249, 249, 249, 0.8);

  &:focus {
    outline: 2px solid #3c79f5;
  }
`;

export default function LoginInput({
  label,
  type,
  name,
  placeholder,
  handleInputUpdate,
}: LoginInputType) {
  return (
    <Label>
      {label}
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={handleInputUpdate}
      />
    </Label>
  );
}
