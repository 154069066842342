import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

const Tooltip = styled.p`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin: 0;
  color: #3c79f5;
  font-size: 14px;
  font-weight: normal;
`;

export default function LoginTooltip() {
  return (
    <Tooltip>
      <FontAwesomeIcon icon={faCircleExclamation} />
      username 또는 password가 올바르지 않습니다.
    </Tooltip>
  );
}
