import React, { useState } from 'react';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';
import { handleLogin, isLoggedIn } from '../../../services/auth';
import LoginForm from '../../molecules/form/LoginForm';

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #ecf0f3;
`;

export default function Login() {
  const [state, setState] = useState({
    username: '',
    password: '',
  });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    handleLogin(state);
  };

  const handleInputUpdate: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  if (isLoggedIn()) {
    navigate(`/`);
  }

  return (
    <LoginContainer>
      <LoginForm
        handleInputUpdate={(e) => handleInputUpdate(e)}
        handleSubmit={(e) => handleSubmit(e)}
      />
    </LoginContainer>
  );
}
